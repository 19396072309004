import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
  selector: 'offconon-range-row-custom-filed-form',
  templateUrl: './range-row-custom-filed-form.component.html',
  standalone: true,
  imports: [InputNumberModule, FormsModule, ButtonModule, MatIcon, TranslateModule],
})
export class RangeRowCustomFiledFormComponent implements OnInit, OnDestroy {
  @Input() si_unit_name: any;

  rows: any[] = [];
  form: FormGroup;
  constructor(private rootFormGroup: FormGroupDirective) {}
  ngOnInit(): void {
    this.form = this.rootFormGroup.control;
    if (this.form.value.range_values && this.form.value.range_values.length > 0) {
      this.rows = this.form.value.range_values;
    } else {
      this.addRow();
    }
  }

  addRow() {
    let row = {
      from_value: '0',
      to_value: '0',
    };
    this.rows.push(row);
    this.ruleChanged();
  }
  deleteRow(index: any) {
    this.rows.splice(index, 1);
    this.ruleChanged();
  }
  ruleChanged() {
    this.form.patchValue({
      range_values: this.rows,
    });
  }

  ngOnDestroy(): void {
    this.form.patchValue({
      range_values: null,
    });
  }
}
