<div
  *ngFor="let row of rows; let i = index; trackBy: trackByIndex"
  class="grid formgrid p-fluid pb-5 pt-3">
  <div class="field mb-1 mt-1 col-12">
    <span class="p-float-label">
      <input type="text" pInputText class="w-full" [(ngModel)]="customValues[i]" />
      <label for="value"> {{ 'Custom value' | translate }}</label>
    </span>
  </div>

  @if (i !== 0) {
    <div class="field mb-1 col-12 pt-1">
      <div class="flex justify-content-end flex-wrap card-container">
        <div class="flex align-items-center justify-content-center m-2">
          <a
            class="p-button-rounded p-button-danger p-button-outlined"
            pButton
            (click)="deleteRow(i)">
            <mat-icon>delete</mat-icon> {{ 'Delete row' | translate }}
          </a>
        </div>
      </div>
    </div>
  }
</div>

<div class="flex align-items-center justify-content-center m-2">
  <a
    class="p-button-rounded w-full p-button-info"
    icon="pi pi-plus"
    pButton
    label="{{ 'Add row' | translate }}"
    (click)="addRow()"></a>
</div>
