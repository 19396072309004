@if (show_all) {
  <p-dropdown
    #systemDataDropdown
    optionLabel="name"
    filterBy="name"
    [styleClass]="'w-full'"
    [virtualScrollItemSize]="pageSize"
    [virtualScroll]="true"
    [appendTo]="'body'"
    [lazy]="true"
    [options]="commonCustomFieldCategory"
    [filter]="true"
    [showClear]="false"
    placeholder="{{ 'Select one' | translate }}"
    [(ngModel)]="selectedCustomField"
    (onLazyLoad)="refreshList($event)"
    (onChange)="select()">
    <ng-template pTemplate="filter">
      <span class="p-input-icon-right w-full">
        <i class="pi pi-search"></i>
        <input
          class="w-full"
          pInputText
          type="text"
          [(ngModel)]="searchString"
          (ngModelChange)="onSearch($event)" />
      </span>
    </ng-template>
    <ng-template pTemplate="selectedItem">
      @if (selectedCustomField) {
        <div>
          {{
            selectedCustomField.keyword?.translations
              ? selectedCustomField.keyword?.translations?.[0]?.translation
              : ''
          }}
        </div>
      }
    </ng-template>
    <ng-template pTemplate="footer">
      @if (dataLoading) {
        <div class="flex flex-wrap align-items-center justify-content-center">
          <div
            class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center text-center text-orange-800 font-italic text-base pt-3 pb-3 line-height-3 px-5 py-3">
            {{ 'In just a few seconds, more options will be loaded!' | translate }}
          </div>
        </div>
      }
    </ng-template>
    <ng-template let-system pTemplate="item">
      <div class="ml-1">
        <span class="block mb-1 text-sm font-medium white-space-nowrap">
          {{ system.keyword?.translations?.[0]?.translation }}
        </span>
        <div class="pb-3">
          <div class="text-500 w-full line-height-3">
            {{ 'Custom field type' | translate }}:
            <span class="text-blue-500 font-italic">
              {{ system.custom_type }}
            </span>
          </div>
          <div class="text-500 w-full line-height-3">
            {{ system.keyword?.translations?.[0]?.description }}
          </div>
        </div>
      </div>
    </ng-template>
  </p-dropdown>
  @if (parameters?.business_id || parameters?.admin_mode) {
    <small class="block cursor-pointer mt-1 line-height-3" (click)="createNew()">
      {{ 'You can create a new one here.' | translate }}
      <span class="text-blue-500">{{ 'Click here' | translate }}</span>
    </small>
  }
}

@if (!show_all) {
  @if (!hide_from_button) {
    <div class="flex justify-content-end flex-wrap">
      <button
        pButton
        type="button"
        icon="pi pi-times"
        class="p-button-rounded p-button-danger flex align-items-center justify-content-center"
        (click)="showAll()"></button>
    </div>
  }
  @if (!hide_from_button) {
    <div class="mb-3 font-bold text-lg text-center">
      <span class="text-blue-600"> {{ 'Create' | translate }}</span>
    </div>
  }

  <offconon-custom-fields-form [workflow_place]="true" (formSavedEvent)="updateData($event)" />
}
