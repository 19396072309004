import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { switchMap, catchError, of, mergeMap } from 'rxjs';

import { SystemService } from '@offconon/shared/features/generated-api';
import { ErrorService } from '@offconon/shared/utils/services';

import * as CustomFieldTemplateActions from './custom-field-template.actions';

@Injectable()
export class CustomFieldTemplateEffects {
  private actions$ = inject(Actions);
  private systemService = inject(SystemService);
  private errorService = inject(ErrorService);

  loadCustomFieldTemplate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomFieldTemplateActions.fetchCustomFieldTemplate),
        mergeMap((payload) =>
          this.systemService
            .systemCustomFieldList(payload.page, payload.pageSize, payload.searchJson)
            .pipe(
              switchMap((paginatedWfSelectedCustomFieldList) => {
                return of(
                  CustomFieldTemplateActions.setLoaded({ loading: true }),
                  CustomFieldTemplateActions.fetchCustomFieldTemplateSuccess({
                    paginatedWfSelectedCustomFieldList,
                  }),
                  CustomFieldTemplateActions.setLoaded({ loading: false }),
                );
              }),
              catchError((error) => {
                this.errorService.showErrorMessage(error);
                return of(CustomFieldTemplateActions.loadCustomFieldTemplateFailure({ error }));
              }),
            ),
        ),
      ),
    { dispatch: true } as any,
  );

  createCustomFieldMulti$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomFieldTemplateActions.createCustomFieldMulti),
      mergeMap((payload) =>
        this.systemService.systemCustomFieldMultiCreate(payload.customFieldTemplate).pipe(
          switchMap((customFieldTemplate) =>
            of(
              CustomFieldTemplateActions.setLoaded({ loading: false }),
              CustomFieldTemplateActions.createCustomFieldTemplateSuccess({ customFieldTemplate }),
              CustomFieldTemplateActions.setLoaded({ loading: false }),
            ),
          ),
          catchError((error) => {
            this.errorService.showErrorMessage(error);

            return of(CustomFieldTemplateActions.loadCustomFieldTemplateFailure({ error }));
          }),
        ),
      ),
    ),
  );

  deleteCustomFieldTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomFieldTemplateActions.deleteCustomField),
      mergeMap((payload) =>
        this.systemService.systemCustomFieldDestroy(payload.id).pipe(
          switchMap(() =>
            of(
              CustomFieldTemplateActions.setLoaded({ loading: true }),
              CustomFieldTemplateActions.deleteCustomFieldTemplateSuccess({ id: payload.id }),
              CustomFieldTemplateActions.setLoaded({ loading: false }),
            ),
          ),
          catchError((error) => {
            this.errorService.showErrorMessage(error);

            return of(CustomFieldTemplateActions.loadCustomFieldTemplateFailure({ error }));
          }),
        ),
      ),
    ),
  );

  // postSelectedCustomField = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(CustomFieldTemplateActions.selectCustomField),
  //     switchMap((payload) =>
  //       this.systemService.systemSelectCustomFieldCreate(payload.selectedCustomField).pipe(),
  //     ),
  //   ),
  // );
}
