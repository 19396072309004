<div class="p-2 panel-size">
  <p-panel [toggleable]="true" [collapsed]="collapsedStatus" [styleClass]="'w-full'">
    <ng-template pTemplate="header">
      <div class="flex align-items-center gap-2 cursor-pointer">
        <span class="font-light text-sm text-primary">
          {{ 'Specifications' | translate | truncate: truncate_number }}</span
        >
      </div>
    </ng-template>

    <ng-template pTemplate="icons">
      <button class="p-panel-header-icon p-link mr-2" (click)="hideAll()">
        <span class="pi pi-file-edit text-primary"></span>
      </button>
    </ng-template>
    <div class="pb-3 pt-3">
      @if (show_all) {
        <div class="text-700 font-light font-italic text-center text-xs line-height-3 mt-2 mb-3">
          {{ desc }}
        </div>

        <p-divider class="w-full" />
        @if ((loaded$ | async) === false; as loading) {
          <offconon-skeleton-render-sample-wide-card [numberOfSkeletonCard]="1" />
        }
        @if (paginatedWfSelectedCustomFieldList$ | async; as paginatedWfSelectedCustomFieldList) {
          @if (
            ((paginatedWfSelectedCustomFieldList?.results &&
              paginatedWfSelectedCustomFieldList.results?.length) ||
              0) > 0
          ) {
            <div class="mt-2">
              @for (
                customField of paginatedWfSelectedCustomFieldList.results;
                track customField.id
              ) {
                <offconon-selected-custom-field-editor-element
                  [selectedCustomField]="customField"
                  [is_editable]="is_editable"
                  (editClickEvent)="editClick($event)"
                  (deleteClickEvent)="confirmDelete($event)" />
              }

              @if ((paginatedWfSelectedCustomFieldList?.total_objects || 0) > 10) {
                <div class="flex justify-content-center flex-wrap pb-5 pt-5">
                  <div class="flex align-items-center justify-content-center">
                    <p-paginator
                      [styleClass]="(loaded$ | async) ? '' : 'pointer-events-none'"
                      [rows]="paginatedWfSelectedCustomFieldList?.page_size || 10"
                      [totalRecords]="paginatedWfSelectedCustomFieldList?.total_objects || 10"
                      [rowsPerPageOptions]="[10, 20, 30]"
                      (onPageChange)="paginationChanged($event)" />
                  </div>
                </div>
              }
            </div>
          } @else {
            <div class="text-center mt-4">
              <div class="text-red-500 text-lg m-2 pb-3">
                {{ 'You have no saved specifications!' | translate }}
              </div>
            </div>
          }
        }
      } @else {
        <div class="flex justify-content-end flex-wrap">
          <button
            pButton
            type="button"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger flex align-items-center justify-content-center"
            (click)="showAll()"></button>
        </div>
        <div class="mb-3 font-bold text-lg text-center">
          <span class="text-blue-600">
            {{ selectedCustomField ? ('Edit' | translate) : ('Create' | translate) }}</span
          >
        </div>

        <offconon-selected-custom-field-editor-form
          [wfSelectedCustomField]="selectedCustomField"
          [parameters]="parameters"
          (hideEvent)="hideSave($event)"
          (closeForm)="closeForm()" />
      }
    </div>
  </p-panel>
</div>

<!--

<div class="p-2">
  <p-card styleClass=" border-round-2xl mb-4 w-full sm:w-25rem md:w-30rem">
    @if (show_all) {
      <div class="pl-2 pr-2">
        <h3 class="font-medium text-center text-blue-600 mb-1">
          {{ 'Specifications' | translate }}
        </h3>

        <div class="text-700 font-light font-italic text-center text-xs line-height-3 mt-2 mb-3">
          {{ desc }}
        </div>
      </div>

      @if (is_editable) {
        <div class="flex justify-content-center flex-wrap pb-3">
          <button
            pButton
            icon="pi pi-plus"
            iconPos="left"
            class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-auto white-space-nowrap"
            style="white-space: nowrap"
            label="{{ 'Add' | translate }}"
            (click)="hideAll()"></button>
        </div>
      }

      <p-divider class="w-full" />
      @if ((loaded$ | async) === false; as loading) {
        <offconon-skeleton-render-sample-wide-card [numberOfSkeletonCard]="1" />
      }
      @if (paginatedWfSelectedCustomFieldList$ | async; as paginatedWfSelectedCustomFieldList) {
        @if (
          ((paginatedWfSelectedCustomFieldList?.results &&
            paginatedWfSelectedCustomFieldList.results?.length) ||
            0) > 0
        ) {
          <div class="mt-2">
            @for (customField of paginatedWfSelectedCustomFieldList.results; track customField.id) {
              <offconon-selected-custom-field-editor-element
                [selectedCustomField]="customField"
                [is_editable]="is_editable"
                (editClickEvent)="editClick($event)"
                (deleteClickEvent)="confirmDelete($event)" />
            }

            @if ((paginatedWfSelectedCustomFieldList?.total_objects || 0) > 10) {
              <div class="flex justify-content-center flex-wrap pb-5 pt-5">
                <div class="flex align-items-center justify-content-center">
                  <p-paginator
                    [styleClass]="(loaded$ | async) ? '' : 'pointer-events-none'"
                    [rows]="paginatedWfSelectedCustomFieldList?.page_size || 10"
                    [totalRecords]="paginatedWfSelectedCustomFieldList?.total_objects || 10"
                    [rowsPerPageOptions]="[10, 20, 30]"
                    (onPageChange)="paginationChanged($event)" />
                </div>
              </div>
            }
          </div>
        } @else {
          <div class="text-center mt-4">
            <div class="text-red-500 text-lg m-2 pb-3">
              {{ 'You have no saved specifications!' | translate }}
            </div>
          </div>
        }
      }
    }
    @if (!show_all) {
      @if (!hide_new_custom_filed) {
        <div class="flex justify-content-end flex-wrap">
          <button
            pButton
            type="button"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger flex align-items-center justify-content-center"
            (click)="showAll()"></button>
        </div>
        <div class="mb-3 font-bold text-2xl text-center">
          <span class="text-blue-600">
            {{ selectedCustomField ? ('Edit' | translate) : ('Create' | translate) }}</span
          >
        </div>
      }
      <offconon-selected-custom-field-editor-form
        [wfSelectedCustomField]="selectedCustomField"
        [parameters]="parameters"
        (hideEvent)="hideSave($event)"
        (closeForm)="closeForm()" />
    }
  </p-card>
</div>
 -->
<p-confirmDialog
  key="customSelectedtemDialog"
  rejectButtonStyleClass="px-5 py-3 p-button-outlined p-button-rounded p-button-warning font-bold w-auto white-space-nowrap"
  acceptButtonStyleClass="px-5 py-3 p-button-outlined p-button-rounded p-button-danger font-bold w-auto white-space-nowrap"
  [breakpoints]="{ '960px': '75vw' }"
  [baseZIndex]="2"
  [appendTo]="'body'"
  rejectLabel="{{ 'Cancel' | translate }}"
  acceptLabel="{{ 'Yes, I want to delete it.' | translate }}" />
