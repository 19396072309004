import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormsModule, Validators } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

import { FormErrorsComponent } from '@offconon/shared/ui/form-elements/form-errors';

@Component({
  selector: 'offconon-add-custom-filed-value-row-form',
  templateUrl: './add-custom-filed-value-row-form.component.html',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ButtonModule,
    MatIcon,
    TranslateModule,
    CommonModule,
    FormErrorsComponent,
  ],
})
export class AddCustomFiledValueRowFormComponent implements OnInit, OnDestroy {
  @Input({ required: true }) form: FormGroup;
  rows: any = [];
  customValues: any = [];

  ngOnInit(): void {
    if (this.form.value.custom_field_values.length > 0) {
      (this.customValues = this.rows = this.form.value.custom_field_values), [Validators.required];
    } else {
      // this.addRow();
    }
  }

  trackByIndex(index: number, item: any): any {
    return index;
  }

  addRow() {
    let row = '';
    this.rows.push(row);
    this.customValues.push(row);
    this.ruleChanged();
  }
  deleteRow(index: any) {
    this.rows.splice(index, 1);
    this.customValues.splice(index, 1);
    this.ruleChanged();
  }
  ruleChanged() {
    this.form.patchValue({
      custom_field_values: this.customValues,
    });
  }

  ngOnDestroy(): void {
    this.form.patchValue({
      custom_field_values: null,
    });
  }
}
