import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { BehaviorSubject, debounceTime, switchMap, tap } from 'rxjs';

import { CommonCustomFieldCategory } from '@offconon/shared/features/generated-api';
import { SystemService } from '@offconon/shared/features/generated-api';
import { CustomFieldsFormComponent } from '@offconon/shared/ui/custom-fields-form';

@Component({
  selector: 'offconon-custom-fields-template-dropdown',
  templateUrl: './custom-fields-template-dropdown.component.html',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    SharedModule,
    InputTextModule,
    ButtonModule,
    CustomFieldsFormComponent,
    TranslateModule,
  ],
})
export class CustomFieldsTemplateDropdownComponent implements OnInit {
  @Output() selectedCustomFieldEvent = new EventEmitter<CommonCustomFieldCategory>();
  @Output() hideEvent = new EventEmitter<any>();
  @Input() selected_id: any;
  @Input() selectFirst = true;
  @Input() parameters: any;

  private destroyRef = inject(DestroyRef);

  commonCustomFieldCategory: CommonCustomFieldCategory[] = [];

  selectedCustomField?: CommonCustomFieldCategory;

  show_all = true;
  hide_from_button = false;

  page = 1;
  pageSize = 50;
  last = 0;
  searchString = '';
  dataLoading = false;

  paginationAndSearch = new BehaviorSubject<{
    page: number;
    pageSize: number;
    searchString?: string;
  }>({ page: this.page, pageSize: this.pageSize });

  private translateService = inject(TranslateService);
  private systemService = inject(SystemService);

  constructor() {}

  ngOnInit(): void {
    this.initializeComponent();
  }

  private initializeComponent() {
    this.getSystemDatas();
  }

  refreshList(event: { first: number; last: number }) {
    if (this.last < event.last) {
      this.last = event.last;

      if (event.last % this.pageSize > this.pageSize - 4) {
        this.page++;
        this.paginationAndSearch.next({
          page: this.page,
          pageSize: this.pageSize,
          searchString: this.searchString,
        });
      }
    }
  }

  onSearch(event: string) {
    this.last = 0;
    this.commonCustomFieldCategory = [];
    this.paginationAndSearch.next({
      page: 1,
      pageSize: this.pageSize,
      searchString: event,
    });
  }

  private getSystemDatas() {
    if (this.selected_id) {
      const isselectedSystemDataInList = this.commonCustomFieldCategory.some(
        (system_data) => system_data.id === this.selected_id,
      );

      if (!isselectedSystemDataInList) {
        this.loadSelectedSystemData();
      }
    }
    this.fetchSystemDataList();
  }

  private loadSelectedSystemData() {
    this.systemService
      .systemCustomFieldList(1, 1, {
        category_ids: this.parameters?.category_ids,
        business_id: this.parameters?.business_id,
        id: this.selected_id,
        filter: this.parameters?.customFieldListFilter
          ? this.parameters?.customFieldListFilter
          : 'all',
      })

      .subscribe({
        next: () => {},
      });
  }

  private fetchSystemDataList() {
    this.paginationAndSearch
      .pipe(
        debounceTime(500),
        tap(() => {
          this.dataLoading = true;
        }),
        switchMap((paginationAndSearchData) => {
          return this.systemService.systemCustomFieldList(
            paginationAndSearchData.page,
            paginationAndSearchData.pageSize,
            {
              category_ids: this.parameters?.category_ids,
              business_id: this.parameters?.business_id,
              id: this.selected_id,
              filter: this.parameters?.customFieldListFilter
                ? this.parameters?.customFieldListFilter
                : 'all',
              translations:
                paginationAndSearchData.searchString?.trim() === '' ? undefined : this.searchString,
            },
          );
        }),

        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((res) => {
        this.commonCustomFieldCategory = res.results || [];

        if (!this.selected_id && this.selectFirst) {
          this.selectedCustomField = this.commonCustomFieldCategory[0];
        }

        this.dataLoading = false;
        this.selectedCustomFieldEvent.emit(this.selectedCustomField);
      });
  }

  select() {
    this.selectedCustomFieldEvent.emit(this.selectedCustomField);
  }

  createNew() {
    this.show_all = false;
    this.hideEvent.emit(true);
  }

  updateData(data: CommonCustomFieldCategory) {
    this.commonCustomFieldCategory = [data, ...this.commonCustomFieldCategory];
    this.selectedCustomField = data;

    this.selectedCustomFieldEvent.emit(this.selectedCustomField);

    this.show_all = true;
    this.hideEvent.emit(false);
  }

  showAll() {
    this.show_all = true;
    this.hideEvent.emit(false);
  }

  getCustomTranslate(type: string) {
    switch (type) {
      case 'text': {
        return this.translateService.instant('Text input field');
        break;
      }
      case 'radio': {
        return this.translateService.instant('Radio button');
        break;
      }
      case 'checkbox': {
        return this.translateService.instant('Checkbox');
        break;
      }
      case 'dropdown': {
        return this.translateService.instant('Dropdown list');
        break;
      }
      case 'multi_select': {
        return this.translateService.instant('Multiselect list');
        break;
      }
      case 'input_number': {
        return this.translateService.instant('Number input field');
        break;
      }
      default: {
        return '';
        break;
      }
    }
  }
}
