<div>
  @for (row of rows; track row; let i = $index) {
    <div class="grid formgrid p-fluid pb-5 pt-3">
      <div class="field mb-4 col-12">
        <label class="text-900"> {{ 'From this number' | translate }}</label>
        <p-inputNumber
          buttonLayout="horizontal"
          inputId="horizontal"
          spinnerMode="horizontal"
          decrementButtonClass="p-button-text p-button-danger py-1 px-1"
          incrementButtonClass="p-button-text p-button-success py-1 px-1"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          [inputStyleClass]="'w-full text-center py-3 px-1 border-transparent text-blue-500'"
          [style]="{ width: '100%' }"
          [styleClass]="'border-1 surface-border border-round '"
          [showButtons]="true"
          [step]="0.01"
          [min]="0.01"
          [max]="9000000000000"
          suffix="  {{ si_unit_name }}"
          [(ngModel)]="rows[i].from_value"
          (ngModelChange)="ruleChanged()" />
        <small class="block mt-1 line-height-3 text-600 text-xs font-italic"
          >{{ 'Select the starting number of the range.' | translate }}
          {{
            'Note that the start number must be 0.01 greater than the end number of the last row range.'
              | translate
          }}
        </small>
      </div>

      <div class="field mb-4 col-12">
        <label class="text-900"> {{ 'Up to this number' | translate }}</label>
        <p-inputNumber
          buttonLayout="horizontal"
          inputId="horizontal"
          spinnerMode="horizontal"
          decrementButtonClass="p-button-text p-button-danger py-1 px-1"
          incrementButtonClass="p-button-text p-button-success py-1 px-1"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          [inputStyleClass]="'w-full text-center py-3 px-1 border-transparent text-blue-500'"
          [style]="{ width: '100%' }"
          [styleClass]="'border-1 surface-border border-round '"
          [showButtons]="true"
          [step]="0.01"
          [min]="0.01"
          [max]="9000000000000"
          suffix="  {{ si_unit_name }}"
          [(ngModel)]="rows[i].to_value"
          (ngModelChange)="ruleChanged()" />
        <small class="block mt-1 line-height-3 text-600 text-xs font-italic">{{
          'Select the end number of the range.' | translate
        }}</small>
      </div>

      @if (i !== 0) {
        <div class="field mb-4 col-12 pt-4">
          <div class="flex justify-content-end flex-wrap card-container">
            <div class="flex align-items-center justify-content-center m-2">
              <button class="bg-red-700 p-button-rounded" pButton (click)="deleteRow(i)">
                <mat-icon>delete</mat-icon> {{ 'Delete row' | translate }}
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  }

  <div class="flex align-items-center justify-content-center m-2">
    <a
      class="p-button-rounded w-full p-button-info"
      icon="pi pi-plus"
      pButton
      label="{{ 'Add row' | translate }}"
      (click)="addRow()"></a>
  </div>
</div>
