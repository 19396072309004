import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CommonCustomFieldCategory } from '@offconon/shared/features/generated-api';
import { CommonHelperService } from '@offconon/shared/utils/services';

@Component({
  selector: 'offconon-selected-custom-field-editor-element',
  templateUrl: './selected-custom-field-editor-element.component.html',
  standalone: true,
  imports: [TranslateModule],
})
export class SelectedCustomFieldEditorElementComponent implements OnInit {
  @Input() selectedCustomField: CommonCustomFieldCategory | null | undefined;
  @Input() is_editable = true;
  @Output() editClickEvent = new EventEmitter<any>();
  @Output() deleteClickEvent = new EventEmitter<any>();

  selectedValue: string | number | null | undefined = '';

  prefix: string | number | null | undefined;
  suffix: string | number | null | undefined;

  public commonHelperService = inject(CommonHelperService);

  ngOnInit(): void {
    this.prefix = this.selectedCustomField?.prefix?.translations?.[0].name;
    this.suffix = this.selectedCustomField?.suffix?.translations?.[0].name;

    switch (this.selectedCustomField?.custom_type) {
      case 'text':
        this.selectedValue =
          this.selectedCustomField.custom_field_values?.[0]?.translations?.[0]?.name || '';
        break;
      case 'radio':
      case 'dropdown':
        this.selectedValue =
          this.selectedCustomField.custom_field_values?.[0]?.translations?.[0]?.name || '';
        break;
      case 'checkbox':
      case 'multi_select':
        this.selectedValue = (this.selectedCustomField.custom_field_values || [])
          .map(({ translations = [] }) => translations?.[0]?.name)
          .join(', ');
        break;
      case 'input_number':
        this.selectedValue = this.selectedCustomField.custom_field_values
          ?.map((value) => value.translations?.[0]?.name)
          .toString();

        break;
      case 'range':
        this.selectedValue = this.selectedCustomField.range_values
          ?.map(({ from_value, to_value }) => `${from_value} - ${to_value}`)
          .toString();
        break;
    }
  }

  editClick() {
    this.editClickEvent.emit(this.selectedCustomField);
  }

  deleteClick() {
    this.deleteClickEvent.emit(this.selectedCustomField);
  }
}
