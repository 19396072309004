import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';

import { AbstractSelectedCustomFieldComponent } from '../class/abstract-selected-custom-field.component';

@Component({
  selector: 'offconon-selected-custom-field-input-number',
  standalone: true,
  imports: [InputNumberModule, ReactiveFormsModule, FormsModule],
  template: `
    @if (control) {
      <p-inputNumber
        #customNumber
        buttonLayout="horizontal"
        inputId="horizontal"
        spinnerMode="horizontal"
        decrementButtonClass="p-button-danger"
        incrementButtonClass="p-button-success"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        [inputStyleClass]="'w-full'"
        [formControl]="control"
        [autofocus]="true"
        [style]="{ width: '100%' }"
        [showButtons]="true"
        [step]="0.01"
        [min]="0.01"
        [max]="9000000000000"
        [suffix]="suffix || ''" />
    }
  `,
  styles: ``,
})
export class SelectedCustomFieldInputNumberComponent extends AbstractSelectedCustomFieldComponent {}
