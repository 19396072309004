import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { CommonCustomFieldCategory } from '@offconon/shared/features/generated-api';
export interface MappedCustomField {
  name: string;
  code: number;
  field_id: number;
}
@Component({
  template: ``,
})
export abstract class AbstractSelectedCustomFieldComponent {
  @Input({ required: true }) control!: FormControl;
  @Input() selectedCustomField: CommonCustomFieldCategory | undefined;
  prefix: string | undefined | null = undefined;
  suffix: string | undefined | null = undefined;

  getSuffix() {
    if (this.selectedCustomField?.suffix && this.selectedCustomField?.si_filter_access === 'No') {
      return this.selectedCustomField?.suffix?.translations?.[0].name;
    }

    if (this.selectedCustomField?.si_filter_access === 'Yes') {
      return this.selectedCustomField?.si_unit?.si_unit_values?.[0]?.translations?.[0].name;
    }
    if (this.selectedCustomField?.si_filter_access === 'Currency') {
      return this.selectedCustomField?.currency;
    }
    return undefined;
  }
}
