import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MultiSelectModule } from 'primeng/multiselect';

import { AbstractSelectedCustomFieldComponent } from '../class/abstract-selected-custom-field.component';

@Component({
  selector: 'offconon-selected-custom-field-multiselect',
  standalone: true,
  imports: [ReactiveFormsModule, MultiSelectModule, TranslateModule],
  template: ` @if (this.selectedCustomField?.custom_field_values) {
    <ng-container>
      <p-multiSelect
        optionLabel="name"
        filterBy="name"
        [styleClass]="'w-full'"
        [panelStyle]="{ width: '100%' }"
        [optionLabel]="'label'"
        [appendTo]="'body'"
        [formControl]="control"
        [display]="'chip'"
        [options]="this.selectedCustomField?.custom_field_values || []"
        [optionValue]="'id'"
        [showClear]="true"
        placeholder="{{ 'Select one' | translate }}">
        <ng-template let-options pTemplate="selectedItems">
          @for (option of options; track option) {
            <div class="country-item country-item-value">
              {{ option.translations?.[0]?.name }}
            </div>
          }
          @if (options?.length === 0) {
            <div class="country-placeholder">
              {{ 'Choose items' | translate }}
            </div>
          }
        </ng-template>
        <ng-template let-optionValue pTemplate="item">
          {{ optionValue.translations?.[0]?.name }}
        </ng-template>
      </p-multiSelect></ng-container
    >
  }`,
  styles: ``,
})
export class SelectedCustomFieldMultiselectComponent extends AbstractSelectedCustomFieldComponent {}
