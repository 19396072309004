import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';

import { AbstractSelectedCustomFieldComponent } from '../class/abstract-selected-custom-field.component';

@Component({
  selector: 'offconon-selected-custom-field-checkbox',
  standalone: true,
  imports: [InputSwitchModule, ReactiveFormsModule, FormsModule],
  template: ` <div class="grid formgrid p-fluid">
    @for (
      custom_field_value of this.selectedCustomField?.custom_field_values;
      track custom_field_value;
      let index = $index
    ) {
      <div class="field col-12 mt-2">
        <span class="inline-flex justify-content-center align-items-center">
          <p-inputSwitch
            [ngModelOptions]="{ standalone: true }"
            [inputId]="'switch' + index"
            [trueValue]="custom_field_value.id"
            [(ngModel)]="inputSwitchList[custom_field_value.id || 0]"
            (onChange)="onInputSwitchChanged()" />
          <label class="ml-3 mb-1">{{ custom_field_value.translations?.[0]?.name }}</label>
        </span>
      </div>
    }
  </div>`,
  styles: ``,
})
export class SelectedCustomFieldCheckboxComponent extends AbstractSelectedCustomFieldComponent {
  inputSwitchList: any = [];
  onInputSwitchChanged() {
    let filteredArray = this.inputSwitchList.filter(Boolean);
    this.control.setValue(filteredArray);
  }
}
