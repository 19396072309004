<form class="grip col-12 max-w-full pb-5" [formGroup]="form" (submit)="submit()">
  <div class="grid formgrid p-fluid">
    @if (!wfSelectedCustomField) {
      <div class="field mb-4 col-12">
        @if (!hide_new_custom_filed) {
          <label class="text-900"> {{ 'Custom field' | translate }}</label>
        }

        <offconon-custom-fields-template-dropdown
          [parameters]="parameters"
          (selectedCustomFieldEvent)="setCustomField($event)"
          (hideEvent)="hideSave($event)" />
      </div>
    }
    @if (wfSelectedCustomField && !hide_new_custom_filed && form) {
      @switch (wfSelectedCustomField.custom_type) {
        @case ('checkbox') {
          <div class="grip col-12 max-w-full pb-1 pt-1">
            <offconon-selected-custom-field-checkbox
              [control]="$any(form.get('selected_universal_language_field_ids'))"
              [selectedCustomField]="wfSelectedCustomField" />
          </div>
        }
        @case ('radio') {
          <div class="col-12">
            <offconon-selected-custom-field-radio-button
              [control]="$any(form.get('selected_universal_language_field_ids'))"
              [selectedCustomField]="wfSelectedCustomField" />
          </div>
        }
        @case ('dropdown') {
          <div class="field col-12">
            <offconon-selected-custom-field-dropdown
              [control]="$any(form.get('selected_universal_language_field_ids'))"
              [selectedCustomField]="wfSelectedCustomField" />
          </div>
        }

        @case ('multi_select') {
          <div class="field col-12">
            <offconon-selected-custom-field-multiselect
              [control]="$any(form.get('selected_universal_language_field_ids'))"
              [selectedCustomField]="wfSelectedCustomField" />
          </div>
        }
        @case ('input_number') {
          <div class="field col-12">
            <offconon-selected-custom-field-input-number
              [control]="$any(form.get('custom_number'))"
              [selectedCustomField]="wfSelectedCustomField" />
          </div>
        }
        @case ('text') {
          <div class="field col-12">
            <offconon-selected-custom-field-input-text
              [control]="$any(form.get('custom_text'))"
              [selectedCustomField]="wfSelectedCustomField" />
          </div>
        }

        @case ('range') {
          <div class="field col-12">
            <offconon-selected-custom-field-range
              [control]="$any(form.get('range_id'))"
              [selectedCustomField]="wfSelectedCustomField" />
          </div>
        }
      }
    }

    @if (!hide_new_custom_filed) {
      <div class="col-12">
        <small class="block line-height-3 text-600 text-xs font-italic">
          {{
            wfSelectedCustomField && wfSelectedCustomField.keyword?.translations?.[0]?.description
          }}</small
        >
      </div>
    }
  </div>

  @if (!hide_new_custom_filed) {
    <button
      class="p-button-rounded w-full mt-4"
      pButton
      type="submit"
      [disabled]="form.invalid"
      label="{{ 'Save' | translate }}"></button>
  }
</form>
