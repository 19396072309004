import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';

import { AbstractSelectedCustomFieldComponent } from '../class/abstract-selected-custom-field.component';

@Component({
  selector: 'offconon-selected-custom-field-radio-button',
  standalone: true,
  imports: [ReactiveFormsModule, RadioButtonModule],
  template: `
    <div class="flex flex-column">
      @for (
        value of this.selectedCustomField?.custom_field_values;
        track value;
        let index = $index
      ) {
        <div class="mt-2">
          <p-radioButton
            [formControl]="control"
            [inputId]="'checkbox' + index"
            [value]="value.id" />
          <label class="ml-2" [for]="'checkbox' + index">{{ value.translations?.[0]?.name }}</label>
        </div>
      }
    </div>
  `,
  styles: ``,
})
export class SelectedCustomFieldRadioButtonComponent extends AbstractSelectedCustomFieldComponent {}
