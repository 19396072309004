<form action="" class="grip col-12 max-w-full pb-5" [formGroup]="form" (submit)="submit()">
  <div class="grid formgrid p-fluid">
    <div class="field mb-4 col-12">
      <label class="font-medium text-900">{{ 'Category' | translate }}</label>

      <offconon-category-path-dropdown-search-common
        [category_type]="'custom_field'"
        [categoryFormControl]="$any(form.get('category_template_id'))"
        [business_id]="
          system_data?.business_id ? system_data?.business_id : parameters?.business_id
        " />
    </div>

    <div class="field mb-4 col-12">
      <label for="custom_type" class="font-medium text-900">{{
        'Custom field type' | translate
      }}</label>
      <p-dropdown
        optionValue="code"
        optionLabel="name"
        [formControlName]="'custom_type'"
        [options]="custom_type_options"
        [showClear]="false" />
      <small class="block mt-1 line-height-3 text-600 text-xs font-italic">
        {{ 'Select the custom field type.' | translate }}</small
      >
      <offconon-shared-ui-form-errors [ctrl]="form.get('custom_type')" />
    </div>

    <div class="field mb-4 col-12">
      <label class="font-medium text-900">{{ 'Language' | translate }}</label>
      <offconon-langauge-dropdown-virtual-scroll-common
        [selectedId]="selected_language_id"
        [parameters]="language_parameters"
        [languageFormControl]="languageFormControl" />

      <small class="block mt-1 line-height-3 text-600 text-xs font-italic">{{
        'Our system supports multilingual display. Select the language you want to add or edit.'
          | translate
      }}</small>
      <offconon-shared-ui-form-errors [ctrl]="form.get('language_id')" />
    </div>

    @if (this.form.value['custom_type'] === 'text') {
      <div class="field mb-4 col-12">
        <span class="p-float-label">
          <input formControlName="prefix" id="prefix" type="text" pInputText class="w-full" />
          <label for="prefix"> {{ 'Prefix' | translate }}</label>
        </span>
        <small class="block mt-1 line-height-3 text-600 text-xs font-italic">
          {{ 'Optional' | translate }}</small
        >
      </div>
    }
    <div class="field mb-4 col-12">
      <span class="p-float-label">
        <input formControlName="name" id="name" type="text" pInputText class="w-full" />
        <label for="name"> {{ 'Name' | translate }}</label>
      </span>
      <small class="block mt-1 line-height-3 text-600 text-xs font-italic">
        {{ 'Mandatory' | translate }}. {{ 'Enter a name for the custom field.' | translate }}</small
      >
      <offconon-shared-ui-form-errors [ctrl]="form.get('name')" />
    </div>
    @if (
      (this.form.value['si_filter_access'] === 'No' && this.form.value['custom_type'] === 'text') ||
      this.form.value['custom_type'] === 'input_number'
    ) {
      <div class="field mb-4 col-12">
        <span class="p-float-label">
          <input formControlName="suffix" id="suffix" type="text" pInputText class="w-full" />
          <label for="suffix"> {{ 'Suffix' | translate }}</label>
        </span>
        <small class="block mt-1 line-height-3 text-600 text-xs font-italic">
          {{
            'You can specify a suffix or even select Si units to appear after the field.'
              | translate
          }}</small
        >
      </div>
    }

    <div class="field mb-4 col-12">
      <label for="description" class="font-medium text-900"> {{ 'Description' | translate }}</label>
      <textarea
        id="description"
        type="text"
        pInputTextarea
        rows="1"
        name="description"
        [autoResize]="true"
        [formControlName]="'description'"></textarea>
      <small class="block mt-1 line-height-3 text-600 text-xs font-italic">
        {{ 'Optional' | translate }}.
        {{
          'This text will appear below the custom field to help describe the purpose of the field.'
            | translate
        }}
      </small>
    </div>

    @if (
      this.form.value['custom_type'] === 'text' ||
      this.form.value['custom_type'] === 'input_number' ||
      this.form.value['custom_type'] === 'range'
    ) {
      <div class="field mb-4 col-12">
        <label for="si_filter_access" class="font-medium text-900">
          {{
            this.form.value['custom_type'] === 'range'
              ? ('Integrated international system of units unit and currency filter' | translate)
              : ('International system of units filter' | translate)
          }}
        </label>
        <p-dropdown
          optionValue="code"
          optionLabel="name"
          [formControlName]="'si_filter_access'"
          [options]="
            this.form.value['custom_type'] === 'range'
              ? si_filter_access_options_range
              : si_filter_access_options
          "
          [showClear]="false" />
        <small class="block mt-1 line-height-3 text-600 text-xs font-italic">
          {{
            'You can optionally choose a suffix from the International System of Units.' | translate
          }}
        </small>
      </div>
    }

    @if (
      this.form.value['custom_type'] === 'range' &&
      this.form.value['si_filter_access'] === 'Currency'
    ) {
      <div class="field mb-4 col-12">
        <label class="text-900"> {{ 'Currency' | translate }}</label>
        <offconon-all-currency-dropdown-list
          [selected_iso_code]="system_data?.currency"
          (selectedCurrencyEvent)="setCurrency($event)" />
        <small class="block mt-1 line-height-3 text-600 text-xs font-italic">{{
          'Select the currency used for the range value.' | translate
        }}</small>
      </div>
    }
    @if (
      this.form.value['si_filter_access'] === 'Yes' &&
      (this.form.value['custom_type'] === 'text' ||
        this.form.value['custom_type'] === 'input_number' ||
        this.form.value['custom_type'] === 'range')
    ) {
      <div class="field mb-4 col-12">
        <label for="si_unit_type_filter" class="font-medium text-900">{{
          'Unit type' | translate
        }}</label>
        <p-dropdown
          optionValue="code"
          optionLabel="name"
          [formControlName]="'si_unit_type_filter'"
          [options]="si_unit_type_filter_options"
          [showClear]="false"
          (onChange)="onChange($event)" />
      </div>

      <div class="field mb-4 col-12">
        <label class="font-medium text-900">{{ 'Unit name' | translate }}</label>
        <offconon-si-units-dropdown-select
          [unit_type]="this.form.value['si_unit_type_filter']"
          (selectedSiUnitEvent)="setSiUnit($event)" />
        <small class="block mt-1 line-height-3 text-600 text-xs font-italic">
          {{ ' Select the name of the unit that will appear as a suffix.' | translate }}
        </small>
      </div>
    }

    @if (
      this.form.value['custom_type'] !== 'text' &&
      this.form.value['custom_type'] !== 'input_number' &&
      this.form.value['custom_type'] !== 'range'
    ) {
      <div class="field mb-4 mt-5 border-top-1 surface-border col-12">
        <div class="text-900 text-xl font-medium mb-2 mt-5 text-center">
          {{ 'Custom field values' | translate }}
        </div>
        <div class="text-700 text-sm font-italic font-light line-height-3 text-center">
          {{ 'These values appear as options in the selected custom field options.' | translate }}
        </div>
      </div>
    }

    @if (this.form.value['custom_type'] === 'range') {
      <div class="field mb-4 mt-5 border-top-1 surface-border col-12">
        <div class="text-900 text-xl font-medium mb-2 mt-5 text-center">
          {{ 'Custom field value range' | translate }}
        </div>
        <div class="text-700 text-sm font-italic font-light line-height-3 text-center">
          {{
            'These value range appear as options in the selected custom field options.' | translate
          }}
        </div>
      </div>
    }
  </div>

  @if (
    this.form.value['custom_type'] !== 'text' &&
    this.form.value['custom_type'] !== 'input_number' &&
    this.form.value['custom_type'] !== 'range'
  ) {
    <offconon-add-custom-filed-value-row-form [form]="form" />
    <div class="pt-3">
      <p-divider class="w-full" />
    </div>
  }

  @if (this.form.value['custom_type'] === 'range') {
    <offconon-range-row-custom-filed-form [si_unit_name]="si_unit_name" />

    <div class="pt-3">
      <p-divider class="w-full" />
    </div>
  }

  <div class="pt-2">
    <button
      class="p-button-rounded w-full mt-4"
      pButton
      type="submit"
      [loading]="(loading$ | async) === true"
      [disabled]="form.invalid"
      label="{{ 'Save' | translate }}"></button>
  </div>
</form>
