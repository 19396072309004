import { createAction, props } from '@ngrx/store';

import {
  CommonCustomFieldCategory,
  PaginatedCommonCustomFieldCategoryList,
  PostCustomFieldCategoryMultiRequest,
  PostWfSelectedCustomFieldRequest,
} from '@offconon/shared/features/generated-api';

import { CustomFieldTemplateEntity } from './custom-field-template.models';

export const initCustomFieldTemplate = createAction('[CustomFieldTemplate Page] Init');

export const loadCustomFieldTemplateSuccess = createAction(
  '[CustomFieldTemplate/API] Load CustomFieldTemplate Success',
  props<{ customFieldTemplate: CustomFieldTemplateEntity[] }>(),
);

export const loadCustomFieldTemplateFailure = createAction(
  '[CustomFieldTemplate/API] Load CustomFieldTemplate Failure',
  props<{ error: any }>(),
);

export const fetchCustomFieldTemplate = createAction(
  '[CustomFieldTemplate Page] Fetch CustomFieldTemplate',
  props<{
    page?: number;
    pageSize?: number;
    searchJson?: {
      service_type?: string;
      service_id?: number;
    };
  }>(),
);

export const fetchCustomFieldTemplateSuccess = createAction(
  '[CustomFieldTemplate/API] Fetch CustomFieldTemplate Success',
  props<{ paginatedWfSelectedCustomFieldList: PaginatedCommonCustomFieldCategoryList }>(),
);

export const deleteCustomField = createAction(
  '[CustomFieldTemplate Page] Delete CustomFieldTemplate',
  props<{
    id: number;
  }>(),
);

export const deleteCustomFieldTemplateSuccess = createAction(
  '[CustomFieldTemplate/API] Delete CustomFieldTemplate Success',
  props<{ id: number }>(),
);

export const createCustomFieldMulti = createAction(
  '[CustomFieldTemplate Page] Create CustomFieldTemplate',
  props<{ customFieldTemplate: PostCustomFieldCategoryMultiRequest }>(),
);

export const createCustomFieldTemplateSuccess = createAction(
  '[CustomFieldTemplate/API] Create CustomFieldTemplate Success',
  props<{ customFieldTemplate: CommonCustomFieldCategory }>(),
);

export const setLoaded = createAction(
  '[CustomFieldTemplate/API] Set Loaded',
  props<{ loading: boolean }>(),
);

export const selectCustomField = createAction(
  '[CustomFieldTemplate Page] Select CustomFieldTemplate',
  props<{ selectedCustomField: PostWfSelectedCustomFieldRequest }>(),
);
