import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

import { AbstractSelectedCustomFieldComponent } from '../class/abstract-selected-custom-field.component';

@Component({
  selector: 'offconon-selected-custom-field-dropdown',
  standalone: true,
  imports: [DropdownModule, ReactiveFormsModule],
  template: `
    <ng-container>
      <p-dropdown
        optionLabel="name"
        [required]="true"
        [optionValue]="'id'"
        [options]="this.selectedCustomField?.custom_field_values || []"
        [formControl]="control"
        [showClear]="false">
        <ng-template let-option pTemplate="item">
          {{ option.translations?.[0]?.name }}
        </ng-template>

        <ng-template let-selectedOption pTemplate="selectedItem">
          {{ selectedOption.translations?.[0]?.name }}
        </ng-template>
      </p-dropdown>
    </ng-container>
  `,
  styles: ``,
})
export class SelectedCustomFieldDropdownComponent extends AbstractSelectedCustomFieldComponent {}
