import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PaginatorModule } from 'primeng/paginator';

import { CustomFieldsTemplateDropdownComponent } from './dropdown/custom-fields-template-dropdown/custom-fields-template-dropdown.component';
import { SelectedCustomFieldEditorFormComponent } from './form/selected-custom-field-editor-form/selected-custom-field-editor-form.component';
import { SelectedCustomFieldCheckboxComponent } from './item/selected-custom-field-checkbox.component';
import { SelectedCustomFieldDropdownComponent } from './item/selected-custom-field-dropdown.component';
import { SelectedCustomFieldEditorElementComponent } from './item/selected-custom-field-editor-element/selected-custom-field-editor-element.component';
import { SelectedCustomFieldInputNumberComponent } from './item/selected-custom-field-input-number.component';
import { SelectedCustomFieldInputTextComponent } from './item/selected-custom-field-input-text.component';
import { SelectedCustomFieldMultiselectComponent } from './item/selected-custom-field-multiselect.component';
import { SelectedCustomFieldRadioButtonComponent } from './item/selected-custom-field-radio-button.component';
import { SelectedCustomFieldRangeComponent } from './item/selected-custom-field-range.component';
import { CustomFieldSelectEditorRenderComponent } from './render/custom-field-select-editor-render/custom-field-select-editor-render.component';
import { CustomFieldTemplateEffects } from './store/custom-field-template.effects';
import * as fromCustomFieldTemplate from './store/custom-field-template.reducer';

@NgModule({
  imports: [
    ButtonModule,
    CardModule,

    ConfirmDialogModule,
    CustomFieldSelectEditorRenderComponent,
    CustomFieldsTemplateDropdownComponent,
    DividerModule,
    DropdownModule,
    FormsModule,
    InputTextareaModule,
    InputTextModule,
    MatIcon,
    PaginatorModule,
    ReactiveFormsModule,
    SelectedCustomFieldCheckboxComponent,
    SelectedCustomFieldDropdownComponent,
    SelectedCustomFieldEditorElementComponent,
    SelectedCustomFieldEditorFormComponent,
    SelectedCustomFieldInputNumberComponent,
    SelectedCustomFieldInputTextComponent,
    SelectedCustomFieldMultiselectComponent,
    SelectedCustomFieldRadioButtonComponent,
    StoreModule.forFeature(
      fromCustomFieldTemplate.CUSTOM_FIELD_TEMPLATE_FEATURE_KEY,
      fromCustomFieldTemplate.customFieldTemplateReducer,
    ),
    EffectsModule.forFeature([CustomFieldTemplateEffects]),
    SelectedCustomFieldRangeComponent,
    TranslateModule,
  ],
})
export class CustomFieldSelectEditorRenderModule {}
