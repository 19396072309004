import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

import { AbstractSelectedCustomFieldComponent } from '../class/abstract-selected-custom-field.component';

@Component({
  selector: 'offconon-selected-custom-field-input-text',
  standalone: true,
  imports: [InputTextModule, ReactiveFormsModule],
  template: `
    <div class="p-inputgroup">
      @if ($any(selectedCustomField)?.prefix?.[0]?.translations?.[0]?.name) {
        <span class="p-inputgroup-addon">{{
          $any(selectedCustomField)?.prefix?.[0]?.translations?.[0]?.name
        }}</span>
      }

      <input type="text" pInputText class="w-full" pInputText [formControl]="control" />
      @if (suffix) {
        <span class="p-inputgroup-addon">{{ suffix }}</span>
      }
    </div>
  `,
  styles: ``,
})
export class SelectedCustomFieldInputTextComponent extends AbstractSelectedCustomFieldComponent {}
