import { JsonPipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

import { AbstractSelectedCustomFieldComponent } from '../class/abstract-selected-custom-field.component';

@Component({
  selector: 'offconon-selected-custom-field-range',
  standalone: true,
  imports: [DropdownModule, ReactiveFormsModule, JsonPipe, FormsModule],
  template: `
    <p-dropdown
      optionLabel="name"
      [required]="true"
      [optionValue]="'id'"
      [formControl]="control"
      [options]="this.selectedCustomField?.range_values || []"
      [showClear]="false">
      <ng-template let-optionValue pTemplate="item">
        {{ optionValue.from_value }} -
        {{ optionValue.to_value }}
        {{ suffix }}
      </ng-template>

      <ng-template let-selectedItem pTemplate="selectedItem">
        @if (selectedItem) {
          {{ selectedItem.from_value }} -
          {{ selectedItem.to_value }}
          {{ suffix }}
        }
      </ng-template>
    </p-dropdown>
  `,
  styles: ``,
})
export class SelectedCustomFieldRangeComponent extends AbstractSelectedCustomFieldComponent {}
