import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

import {
  CommonCustomFieldCategory,
  PostWfSelectedCustomFieldRequest,
  SelectUniversalLanguage,
} from '@offconon/shared/features/generated-api';

import { CustomFieldsTemplateDropdownComponent } from '../../dropdown/custom-fields-template-dropdown/custom-fields-template-dropdown.component';
import { SelectedCustomFieldCheckboxComponent } from '../../item/selected-custom-field-checkbox.component';
import { SelectedCustomFieldDropdownComponent } from '../../item/selected-custom-field-dropdown.component';
import { SelectedCustomFieldInputNumberComponent } from '../../item/selected-custom-field-input-number.component';
import { SelectedCustomFieldInputTextComponent } from '../../item/selected-custom-field-input-text.component';
import { SelectedCustomFieldMultiselectComponent } from '../../item/selected-custom-field-multiselect.component';
import { SelectedCustomFieldRadioButtonComponent } from '../../item/selected-custom-field-radio-button.component';
import { SelectedCustomFieldRangeComponent } from '../../item/selected-custom-field-range.component';
import { selectCustomField } from '../../store/custom-field-template.actions';

@Component({
  selector: 'offconon-selected-custom-field-editor-form',
  templateUrl: './selected-custom-field-editor-form.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CustomFieldsTemplateDropdownComponent,
    SelectedCustomFieldCheckboxComponent,
    SelectedCustomFieldRadioButtonComponent,
    SelectedCustomFieldDropdownComponent,
    SelectedCustomFieldMultiselectComponent,
    SelectedCustomFieldInputNumberComponent,
    SelectedCustomFieldInputTextComponent,
    SelectedCustomFieldRangeComponent,
    ButtonModule,
    TranslateModule,
  ],
})
export class SelectedCustomFieldEditorFormComponent implements OnInit {
  @Input() parameters: any;
  @Input() wfSelectedCustomField: CommonCustomFieldCategory | null;
  @Input() customFieldValues?: SelectUniversalLanguage[];
  @Output() hideEvent = new EventEmitter<any>();
  @Output() closeForm = new EventEmitter<boolean>();
  form: FormGroup;
  hide_new_custom_filed = false;
  prefix: string | undefined | null = undefined;
  suffix: string | undefined | null = undefined;

  private store$ = inject(Store);

  private fb = inject(FormBuilder);

  ngOnInit() {
    this.form = this.fb.group({
      custom_field_id: [this.wfSelectedCustomField?.id],
      service_type: [this.parameters?.service_type, Validators.required],
      service_id: [this.parameters?.service_id, Validators.required],
      business_id: [this.parameters?.business_id],
      custom_number: [undefined],
      custom_text: [undefined],
      selected_universal_language_field_ids: [undefined],
      range_id: [undefined],
    });
    this.form.patchValue({});
  }

  setCustomField(customField: CommonCustomFieldCategory) {
    if (customField) {
      this.form.patchValue({
        custom_field_id: customField.id,
      });
    }
  }

  hideSave(data: any) {
    this.hide_new_custom_filed = data;
    this.hideEvent.emit(data);
  }

  submit() {
    const asd: PostWfSelectedCustomFieldRequest = {
      custom_field_id: this.form.value.custom_field_id,
      service_type: this.form.value?.service_type,
      service_id: Number(this.form.value.service_id),
      business_id: this.form.value.business_id,
      custom_number: this.form.value.custom_number ?? undefined,
      custom_text: this.form.value.custom_text ?? undefined,
      range_id: this.form.value.range_id ?? undefined,
      selected_universal_language_field_ids: this.getUniversalLanguageFieldIds(),
    };
    this.store$.dispatch(selectCustomField({ selectedCustomField: asd }));

    this.closeForm.emit(true);
  }

  getUniversalLanguageFieldIds() {
    if (!this.form.value.selected_universal_language_field_ids) {
      return undefined;
    }
    if (!Array.isArray(this.form.value.selected_universal_language_field_ids)) {
      return [this.form.value.selected_universal_language_field_ids];
    }

    return this.form.value.selected_universal_language_field_ids;
  }
}
