@if (selectedCustomField?.keyword?.translations?.[0]?.translation) {
  <div class="border-bottom-1 border-300 pb-2">
    <dt class="font-bold mt-3">
      {{ selectedCustomField?.keyword?.translations?.[0]?.translation }}
    </dt>
    <dd class="font-light line-height-3 font-500">
      @if (prefix) {
        <span class="text-red-500"> {{ prefix }} </span>
      }
      <span class="font-italic"> {{ selectedValue }}</span>
      @if (suffix) {
        <span class="text-blue-500"> {{ suffix }} </span>
      }
    </dd>

    @if (is_editable) {
      <div class="flex flex-row-reverse flex-wrap">
        <div class="flex align-items-center justify-content-center" (click)="deleteClick()">
          <span class="text-xs text-red-700 m-2 cursor-pointer">
            <i class="pi pi-trash mr-1 text-xs"></i>
            {{ 'Delete' | translate }}
          </span>
        </div>
        <div class="flex align-items-center justify-content-center" (click)="editClick()">
          <span class="text-xs text-teal-700 m-2 cursor-pointer">
            <i class="pi pi-user-edit mr-1 text-xs"></i>
            {{ 'Edit' | translate }}
          </span>
        </div>
      </div>
    }
  </div>
}
